<template>
    <v-container fluild>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters class="mb-3">
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{ $store.getters.getTextMap().top_5_reasons }}</h2>
            </v-col>
        </v-row>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row no-gutters>
            <v-col
                v-for="(item, index) in top5Reasons"
                :key="index"
                class="d-flex justify-center"
                cols="12" sm="6" md="3" lg="3"
            >
                <v-card
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().background2ColorCode"
                    class="mx-1 my-1"
                    elevation="3"
                    style="max-width: 400px; border-radius: 12px;"
                >
                    <v-card-title class="text-h6 font-weight-bold" :style="{ color: $store.getters.getColorPalette().accentCode }">
                        {{ item.downtime_reason }}
                    </v-card-title>
                    <v-divider class="my-2"></v-divider>
                    <v-card-subtitle class="text-subtitle-1" :style="{ color: $store.getters.getColorPalette().textColor }">
                        <v-icon left color="primary">mdi-clock-outline</v-icon>
                        {{ item && item.total_downtime_duration?((Number(item.total_downtime_duration)/60).toFixed()):0 }} hrs ({{item && item.total_downtime_duration?item.total_downtime_duration:0}} Mins)
                    </v-card-subtitle>
                    <v-divider class="my-2"></v-divider>
                    <v-card-text>
                        <p class="text-body-2">This reason accounted for <span :style="{ color: $store.getters.getColorPalette().textColor }">{{ item && item.total_downtime_duration?((Number(item.total_downtime_duration)/60).toFixed()):0 }} hours</span> of downtime.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="!(top5Reasons && top5Reasons.length>0)">
            <v-col>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-divider class="my-2" :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row class="my-3">
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{ $store.getters.getTextMap().top_5_categories }}</h2>
            </v-col>
        </v-row>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row no-gutters>
            <v-col
                v-for="(item, index) in top5Category"
                :key="index"
                class="d-flex justify-center"
                cols="12" sm="9" md="3"
            >
                <v-card
                    flat
                    outlined
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().background2ColorCode"
                    class="mx-1 my-1"
                    elevation="3"
                    style="max-width: 400px; border-radius: 12px;"
                >
                    <v-card-title class="text-h6 font-weight-bold" :style="{ color: $store.getters.getColorPalette().accentCode }">
                        {{ item.category }}
                    </v-card-title>
                    <v-divider class="my-2"></v-divider>
                    <v-card-subtitle class="text-subtitle-1" :style="{ color: $store.getters.getColorPalette().textColor }">
                        <v-icon left color="primary">mdi-clock-outline</v-icon>
                        {{ item && item.total_downtime_duration?((Number(item.total_downtime_duration)/60).toFixed()):0 }} hrs ({{item && item.total_downtime_duration?item.total_downtime_duration:0}} Mins)
                    </v-card-subtitle>
                    <v-divider class="my-2"></v-divider>
                    <v-card-text>
                        <p class="text-body-2">This reason accounted for <span :style="{ color: $store.getters.getColorPalette().textColor }">{{ item && item.total_downtime_duration?((Number(item.total_downtime_duration)/60).toFixed()):0 }} hours</span> of downtime.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="!(top5Category && top5Category.length>0)">
            <v-col>
                <div :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-divider class="my-2" :dark="$store.getters.getColorPalette().isDark"></v-divider>
        <v-row class="mx-auto">
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <div :style="[{'display':chategoryChartDisplay}]">
                    <div ref="vis"></div>
                </div>
                <div v-if="!(dbDataByCategory &&dbDataByCategory.length>0  && !loading)" :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-auto">
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <div :style="[{'display':reasonChartDisplay}]">
                    <div ref="vis2"></div>
                </div>
                <div v-if="!(dbDataByReason &&dbDataByReason.length>0 && !loading)"  :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                    <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import Plotly from 'plotly.js';
// import moment from "moment";

export default {
    name: 'DowntimeReasonsPiechart',
    props: ["shift", "machines", "from_date", "to_date"],
    components: {
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading: false,
            info: '',
            dbDataByCategory: [],
            dbDataByReason: [],
            top5Reasons: [],
            top5Category: [],
            chategoryChartDisplay:"none",
            reasonChartDisplay:"none",
            layout: {
                colorway: this.$store.getters.getColorPalette().colorTypesCodes,
                font: {
                    color: this.$store.getters.getColorPalette().accentCode
                },
                plot_bgcolor: this.$store.getters.getColorPalette().backgroundColorCode,
                paper_bgcolor: this.$store.getters.getColorPalette().background2ColorCode,
                showlegend: true,
                automargin: true,
                legend: {
                    bgcolor: '#00000000',
                    font: {
                        size: 8,
                    },
                },
                autosize: true,
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    automargin: true,
                },
                yaxis: {
                    showticklabels: true,
                    automargin: true,
                },
                uniformtext: {
                    mode: "hide",
                    minsize: 1
                }
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.chategoryChartDisplay = "none"
            this.reasonChartDisplay="none"
            if (this.from_date) {
                this.getDataByCategory();
                this.getDataByReason();
            }
        },
        createCategoryChart() {
            if (!(this.dbDataByCategory && this.dbDataByCategory.length > 0)) {
                this.chategoryChartDisplay = "none"
                return
            }
            let graphData = {};
            for (let i of this.dbDataByCategory) {
                graphData[i.category] = i.total_downtime_duration;
            }
            let textInfo = 'value';
            this.layout['title'] = "Downtime By Categories";
            this.layout['colorway'] = Object.values(this.$store.getters.getColorPalette().colorTypesCodes);
                this.layout['annotations'] = [{
                    font: { size: 22 },
                    showarrow: false,
                    text: "Minutes",
                    x: 0.5,
                    y: 0.5
                }];
                
                const data = [{
                    values: Object.values(graphData),
                    labels: Object.keys(graphData),
                    type: 'pie',
                    text: "Minutes",
                    textinfo: textInfo,
                    textposition: "outside",
                    opacity: 1,
                    hole: 0.7,
                    marker: {
                        line: {
                            color: this.$store.getters.getColorPalette().accentCode,
                            width: 1
                        },
                    }
                }];
                
                if (this.$refs.vis) {
                    this.chategoryChartDisplay="initial"
                    Plotly.newPlot(this.$refs.vis, data, this.layout, { displaylogo: false });
                }
        },
        createReasonChart() {
            if (!(this.dbDataByReason && this.dbDataByReason.length > 0)) {
                this.reasonChartDisplay = "none"
                return
            }
            let graphData = {};
            for (let i of this.dbDataByReason) {
                graphData[i.downtime_reason] = i.total_downtime_duration;
            }
            let textInfo = 'value';
            this.layout['title'] = "Downtime By Reasons";
            this.layout['colorway'] = Object.values(this.$store.getters.getColorPalette().colorTypesCodes);
            this.layout['annotations'] = [{
                font: { size: 22 },
                showarrow: false,
                text: "Minutes",
                x: 0.5,
                y: 0.5
            }];
            
            const data = [{
                values: Object.values(graphData),
                labels: Object.keys(graphData),
                type: 'pie',
                text: "Minutes",
                textinfo: textInfo,
                textposition: "outside",
                opacity: 1,
                hole: 0.7,
                marker: {
                    line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                    },
                }
            }];
            if (this.$refs.vis2) {
                this.reasonChartDisplay="initial"
                Plotly.newPlot(this.$refs.vis2, data, this.layout, { displaylogo: false });
            }
        },
        createTopFiveReasonsData() {
            let temp = this.dbDataByReason.map(obj => ({
                ...obj
            }))
            this.top5Reasons = temp.sort((a, b) => b.total_downtime_duration - a.total_downtime_duration).slice(0, 5);
        },
        createTopFiveCategoryData() {
            let temp = this.dbDataByCategory.map(obj => ({
                ...obj
            }))
            this.top5Category = temp.sort((a, b) => b.total_downtime_duration - a.total_downtime_duration).slice(0, 5);
        },
        getDataByReason() {
            this.dbDataByReason = [];
            this.top5Reasons=[]
            this.loading = true;
            const payload = {
                machines: this.machines,
                shift: this.shift,
                from_date: this.from_date,
                to_date: this.to_date
            };
            axios.post(this.$store.state.api + "getTotalDowntimeGroupedByReasons", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status == "success") {
                        this.dbDataByReason = response.data.data;
                        this.$nextTick(() => {
                            this.createReasonChart()
                            this.createTopFiveReasonsData()
                        });
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
        },
        getDataByCategory() {
            this.dbDataByCategory = [];
            this.top5Category=[]
            this.loading = true;
            const payload = {
                machines: this.machines,
                shift: this.shift,
                from_date: this.from_date,
                to_date: this.to_date
            };
            axios.post(this.$store.state.api + "getTotalDowntimeGroupedByCategories", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status == "success") {
                        this.dbDataByCategory = response.data.data;
                        this.$nextTick(() => {
                            this.createCategoryChart()
                            this.createTopFiveCategoryData()
                        });
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
        }
    },
    watch: {
        // dbDataByCategory(newData, oldData) {
        //     if (newData !== oldData && newData.length > 0) {
        //         this.createCategoryChart();
        //         this.createTopFiveCategoryData();
        //     }
        // },
        // dbDataByCategory:{
        //     handler(){
        //         this.createCategoryChart();
        //         this.createTopFiveCategoryData();
        //     },
        //     deep:true
        // },
        // dbDataByReason:{
        //     handler(){
        //         this.createReasonChart();
        //         this.createTopFiveReasonsData();
        //     },
        //     deep:true
        // },
        // dbDataByReason(newData, oldData) {
        //     if (newData !== oldData && newData.length > 0) {
        //         this.createReasonChart();
        //         this.createTopFiveReasonsData();
        //     }
        // },
        shift() {
            this.init();
        },
        machines() {
            this.init();
        },
        from_date() {
            this.init();
        },
        to_date() {
            this.init();
        },
    }
};
</script>
