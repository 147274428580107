var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert=false}}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.oeeData)?_c('v-card',{style:({'border-radius':'5px','border':'1px solid'+_vm.$store.getters.getColorPalette().panelBorderColorCode}),attrs:{"flat":"","dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode}},[_c('div',{staticClass:"chart-containe"},[_c('apexchart',{ref:"heatMap",attrs:{"type":"radialBar","options":_vm.getChartOptions(),"series":_vm.seriesData}})],1),_c('div',{style:({
                    'display':'flex',
                    'justify-content':'space-between',
                    'padding':'20px',
                    'margin':'10px',
                    'border':'1px solid' + _vm.$store.getters.getColorPalette().panelBorderColorCode
                })},[_c('div',{style:({
                        'display':'flex',
                        'flex-direction':'column',
                        'justify-content':'center',
                        'align-items':'center',
                        'gap':'10px',
                        'padding-right': '20px',
                        'color':_vm.$store.getters.getColorPalette().submitbtnColor,
                        'border-right':'1px solid' + _vm.$store.getters.getColorPalette().panelBorderColorCode
                    })},[_c('h3',[_vm._v("Target")]),_c('h4',[_vm._v("80%")])]),_c('div',{style:({
                        'display':'flex',
                        'flex-direction':'column',
                        'justify-content':'center',
                        'align-items':'center',
                        'gap':'10px',
                        'padding-right':'20px',
                        // 'border-right':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                    })},[_c('h3',[_vm._v("Availability")]),_c('h4',[_vm._v(_vm._s((_vm.oeeData.avg_availability*100).toFixed())+"%")])]),_c('div',{style:({
                        'display':'flex',
                        'flex-direction':'column',
                        'justify-content':'center',
                        'align-items':'center',
                        'gap':'10px',
                        'padding-right':'20px',
                        // 'border-right':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                    })},[_c('h3',[_vm._v("Performance")]),_c('h4',[_vm._v(_vm._s((_vm.oeeData.avg_performance*100).toFixed())+"%")])]),_c('div',{style:({
                        'display':'flex',
                        'flex-direction':'column',
                        'justify-content':'center',
                        'align-items':'center',
                        'gap':'10px'
                    })},[_c('h3',[_vm._v("Quality")]),_c('h4',[_vm._v(_vm._s((_vm.oeeData.avg_quality*100).toFixed())+"%")])])])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }