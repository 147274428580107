<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-row no-gutters>
            <v-col>
                <v-card v-if="oeeData" :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                    <div class="chart-containe">
                        <apexchart
                            ref="heatMap"
                            type="radialBar"
                            :options="getChartOptions()"
                            :series="seriesData"
                        ></apexchart>
                    </div>
                    <!-- <div :style="{
                        'display':'flex',
                        'flex-direction':'column',
                        'justify-content':'center',
                        'align-items':'center',
                        'gap':'10px',
                        'margin-top':'5px',
                        'margin-bottom':'15px',
                        'padding-right': '20px',
                        'color':$store.getters.getColorPalette().submitbtnColor
                    }">
                        <h1>Target</h1>
                        <h3>80%</h3>
                    </div> -->
                    <div :style="{
                        'display':'flex',
                        'justify-content':'space-between',
                        'padding':'20px',
                        'margin':'10px',
                        'border':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                    }">
                        <!-- Target Column -->
                        <div :style="{
                            'display':'flex',
                            'flex-direction':'column',
                            'justify-content':'center',
                            'align-items':'center',
                            'gap':'10px',
                            'padding-right': '20px',
                            'color':$store.getters.getColorPalette().submitbtnColor,
                            'border-right':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                        }">
                            <h3>Target</h3>
                            <h4>80%</h4>
                        </div>

                        <!-- Availability Column -->
                        <div :style="{
                            'display':'flex',
                            'flex-direction':'column',
                            'justify-content':'center',
                            'align-items':'center',
                            'gap':'10px',
                            'padding-right':'20px',
                            // 'border-right':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                        }">
                            <h3>Availability</h3>
                            <h4>{{ (oeeData.avg_availability*100).toFixed() }}%</h4>
                        </div>

                        <!-- Performance Column -->
                        <div :style="{
                            'display':'flex',
                            'flex-direction':'column',
                            'justify-content':'center',
                            'align-items':'center',
                            'gap':'10px',
                            'padding-right':'20px',
                            // 'border-right':'1px solid' + $store.getters.getColorPalette().panelBorderColorCode
                        }">
                            <h3>Performance</h3>
                            <h4>{{ (oeeData.avg_performance*100).toFixed() }}%</h4>
                        </div>

                        <!-- Quality Column -->
                        <div :style="{
                            'display':'flex',
                            'flex-direction':'column',
                            'justify-content':'center',
                            'align-items':'center',
                            'gap':'10px'
                        }">
                            <h3>Quality</h3>
                            <h4>{{ (oeeData.avg_quality*100).toFixed() }}%</h4>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import VueApexCharts from "vue-apexcharts";
export default {
    name: 'OEERadialBar',
    props: ["shift", "machines", "from_date", "to_date"],
    components: {
        InfoAlert,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading:false,
            info: '',
            seriesData:[],
            oeeData: {},
            oee:0,
            objectRules: [
                v => !!v || this.$store.getters.getTextMap().required,
            ],
        }
    },
    mounted() {
        this.getOEEData()
    },
    methods: {
        getChartOptions() { 
            return {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                color: this.$store.getters.getColorPalette().accentCode,
                            },
                            total: {
                                show: true,
                                label: 'OEE',
                                formatter: () => {
                                    return this.oee.toFixed(2);
                                },
                                color: this.$store.getters.getColorPalette().accentCode,
                            }
                        }
                    }
                },
                labels: ['Availability', 'Performance', 'Quality'],
            }
        },
       getOEEData() {
            this.loading = true;
            const payload = {
                machines: this.machines,
                shift: this.shift,
                from_date: this.from_date,
                to_date: this.to_date,
                getAvgData:true
           };
            axios.post(this.$store.state.api + "getAvgerageOEEDataBetweenDate", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {
                if (response.data.status == "success") {
                    let dbData = response.data.data[0];
                    this.oee = dbData.oee
                    this.oeeData=dbData
                    this.seriesData=[(dbData.avg_availability*100).toFixed(),(dbData.avg_performance*100).toFixed(),(dbData.avg_quality*100).toFixed()]
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        shift() {
            this.getOEEData();
        },
        machines() {
            this.getOEEData();
        },
        from_date() {
            this.getOEEData();
        },
        to_date() {
            this.getOEEData();
        },
    }
}
</script>